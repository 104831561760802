@import 'base';

html { font-size: 80%; box-sizing: border-box; }
body { color: #000 !important; background: #fff !important; padding-top: 0; }

.l-Site__skipLinks,
.l-Site__compatWarnings,
.l-Site__header,
.l-Site__footer,
.l-Site__hero,
.l-Page__crumbs,
.BlogPost__related,
.CaseStudy__related,
.ShareButtons,
.Pagination {
	display: none !important;
}
.l-Site__container {
	max-width: unset !important;
	padding: 0 !important;
}
.o-Block__container {
	max-width: unset !important;
	margin-top: 2rem !important;
}
.l-Page__block,
.o-Block__container--Group > :not(.o-Block--Grouped--FullSize) {
    max-width: unset !important;
}
.l-Page__block {
    margin-top: 0;
}

.l-Site__block--ElementCTA {
    display: none !important;
}

.o-Block.-slant-bottom-left,
.o-Block.-slant-bottom-right,
.o-Block.-slant-top-left,
.o-Block.-slant-top-right {
    padding-bottom: 0;
    margin-bottom: 0;
    padding-top: 0;
    margin-top: 0;

    & > .l-Site__container::before,
    & > .l-Site__container::after {
        content: none;
    }
}

.Testimonial::after {
    content: none;
}

.Card {
    page-break-inside: avoid;
}
